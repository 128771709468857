import { graphql } from 'gatsby';
import * as _ from 'lodash/fp';
import * as React from 'react';
import Helmet from 'react-helmet';
import { Box, Grommet, Text, Anchor, Heading, Grid, Carousel } from 'grommet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import siteConfig from '../../site-config';
const { colors } = siteConfig;

import Layout from '../components/Layout';
// import console = require('console');
import Slider from "react-slick";


import Img from "gatsby-image"


interface BlogIndexProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
      };
    };
    allMarkdownRemark: {
      edges: any;
    };
    allImageSharp: {
      edges: any;
    };
  };
}

const GlobalStyle = createGlobalStyle`
  .slick-dots {
    bottom: ${props => props.isSmall ? '30px !important' : "50px !important"};


     & > li > button:before {
       font-size: 12px !important;
       -webkit-text-stroke: 1px rgba(255,255,255,0.5);
     }
  }
  // .slick-arrow,
  .slick-next:before, 
  .slick-prev:before
   {
     font-size: 36px !important;
    color: rgba(0,0,0,0.9) !important;
  }

  .slick-prev {
    width: 36px !important;
    height: 36px !important;
    margin-top: 36px !important;

    z-index: 2;
    left:  ${props => props.isLarge ? '25px !important' : '15px !important'}
  }
  .slick-next {
    width: 36px !important;
    height: 36px !important;
    margin-top: 36px !important;

    z-index: 2;
    right:  ${props => props.isLarge ? '25px !important' : '15px !important'}
  }

`;


const CaptionBox = styled(Box)`
background: white;
width: 100%;
h4 { 
  font-weight: 300;
  margin: 0;
}
h2 { 
  text-transform: uppercase;
  font-weight: 400;
  letter-spaceing: 2px;  
  margin: 0;
}
margin:  ${props => props.isLarge ? props.reverse ? '120px -120px' : '120px' : '0'};
z-index: 10;


`



const ImageWrapper = styled(Box)`
  max-height: 700px;
`;



const BlogIndex = ({ data, browser, location }: BlogIndexProps) => {
  const siteTitle = data.site.siteMetadata.title;
  const siteDescription = data.site.siteMetadata.description;
  // const posts = data.allMarkdownRemark.edges;
  const images = data.allImageSharp.edges
    .sort((a, b) => {
      const asrc = a.node.fluid.src.split("/")[4];
      const bsrc = b.node.fluid.src.split("/")[4];

      if (asrc < bsrc) { return -1; }
      if (asrc > bsrc) { return 1; }
      return 0;
    })

  console.info({images})
  const isLarge = browser.greaterThan.medium;
  const isSmall = browser.lessThan.medium;

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    fade: true,
    arrows: true,
    pauseOnDotsHover: true,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
  };


  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={siteTitle}>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500" />
      </Helmet>
      <GlobalStyle isSmall={isSmall} isLarge={isLarge} />

      <main>
        <Box gap='small'>


          <Box direction={isLarge ? 'row' : 'column-reverse'}>
            <Box basis='1/3' flex='grow'>

              <Box align='start' justify='start' flex='grow'>
                <CaptionBox isLarge={isLarge} pad='small'>
                  <Heading level='2' margin={{ vertical: 'small' }}>
                    Feel at home.
              </Heading>
                  <Heading level='4'>
                   The living room is perfect for relaxing from the day, if it's not enough, enjoy your private balcony and breath in some fresh sea breeze.
                   
              </Heading>
                </CaptionBox>
              </Box>

            </Box>

            <Slider {...settings} style={isLarge ? { minWidth: '960px' } : {}}>
          {/* <Stack fill> */}
          {/* <Heading>Your Home in Tel Aviv</Heading> */}
          {images && images.filter((img, i) => i < 4).map((edge, i) =>
            <Box key={i}>
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[i].node.fluid.src}_stat`}
                fluid={images[i].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>

            </Box>

          )}
          {/* </Stack> */}

        </Slider>

          </Box>
          <Box direction={isLarge ? 'row-reverse' : 'column-reverse'}>
            <Box basis='1/3' flex='grow'>

              <Box align='start' justify='start' flex='grow'>
                <CaptionBox isLarge={isLarge} reverse pad='small'>
                  <Heading level='2' margin={{ vertical: 'small' }}>
                   Master Bedroom
              </Heading>
                  <Heading level='4'>
                   With everything you need including a private bathroom, full closet, work table, bedside tables, balcony access, and last but not least luxury bed that will help you're body feel great.
              </Heading>
                </CaptionBox>
              </Box>

            </Box>

            <Slider {...settings} style={isLarge ? { minWidth: '960px' } : {}}>
          {/* <Stack fill> */}
          {/* <Heading>Your Home in Tel Aviv</Heading> */}
          {images && images.map((edge, i) =>
            (i > 4 && i < 9) ? 

(            <Box key={i}>
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[i].node.fluid.src}_stat`}
                fluid={images[i].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>

            </Box>) : null

          )}
          {/* </Stack> */}

        </Slider>

          </Box>
          
          <Box direction={isLarge ? 'row' : 'column-reverse'}>
            <Box basis='1/3' flex='grow'>

              <Box align='start' justify='start' flex='grow'>
                <CaptionBox isLarge={isLarge} pad='small'>
                  <Heading level='2' margin={{ vertical: 'small' }}>
                    Full Kitchen
              </Heading>
                  <Heading level='4'>
                   Kitchen and dining table equipped with everything you need to cook any meal you crave.
                   Refrigirator, oven, stove, dishes and pots, Nespresso Machine, fresh water bar, soda machine and much more.
              </Heading>
                </CaptionBox>
              </Box>

            </Box>
            <Slider {...settings} style={isLarge ? { minWidth: '960px' } : {}}>
          {/* <Stack fill> */}
          {/* <Heading>Your Home in Tel Aviv</Heading> */}
          {images && images.map((edge, i) =>
            (i > 8 && i < 15) ? 

(            <Box key={i}>
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[i].node.fluid.src}_stat`}
                fluid={images[i].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>

            </Box>) : null

          )}
          {/* </Stack> */}

        </Slider>
          </Box>

          <Box direction={isLarge ? 'row-reverse' : 'column-reverse'}>
            <Box basis='1/3' flex='grow'>

              <Box align='start' justify='start' flex='grow'>
                <CaptionBox isLarge={isLarge} reverse pad='small'>
                  <Heading level='2' margin={{ vertical: 'small' }}>
                   Guest Bedroom
              </Heading>
                  <Heading level='4'>
                   Two extra single super comfort single beds for kids or guests with a separate bathroom.
              </Heading>
                </CaptionBox>
              </Box>

            </Box>
            <Slider {...settings} style={isLarge ? { minWidth: '960px' } : {}}>
          {/* <Stack fill> */}
          {/* <Heading>Your Home in Tel Aviv</Heading> */}
          {images && images.map((edge, i) =>
            (i > 12 && i < 16) ? 

(            <Box key={i}>
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[i].node.fluid.src}_stat`}
                fluid={images[i].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>

            </Box>) : null

          )}
          {/* </Stack> */}

        </Slider>
          </Box>

          <Box direction={isLarge ? 'row' : 'column-reverse'}>
            <Box basis='1/3' flex='grow'>

              <Box align='start' justify='start' flex='grow'>
                <CaptionBox isLarge={isLarge} pad='small'>
                  <Heading level='2' margin={{ vertical: 'small' }}>
                    Private Balcony
              </Heading>
                  <Heading level='4'>
                    Enjoy and relax in the spacious balcony to the sound of flowing water from the fountain downstairs.
              </Heading>
                </CaptionBox>
              </Box>

            </Box>
            <Slider {...settings} style={isLarge ? { minWidth: '960px' } : {}}>
          {/* <Stack fill> */}
          {/* <Heading>Your Home in Tel Aviv</Heading> */}
          {images && images.map((edge, i) =>
            (i > 15 && i < 18) ? 

(            <Box key={i}>
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[i].node.fluid.src}_stat`}
                fluid={images[i].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>

            </Box>) : null

          )}
          {/* </Stack> */}

        </Slider>
          </Box>

          <Box direction={isLarge ? 'row-reverse' : 'column-reverse'} style={{marginBottom: '-5px'}}>
            <Box basis='1/3' flex='grow'>

              <Box align='start' justify='start' flex='grow'>
                <CaptionBox isLarge={isLarge} reverse pad='small'>
                  <Heading level='2' margin={{ vertical: 'small' }}>
                   Premium Compound
              </Heading>
                  <Heading level='4'>
                  Private, secure and close to everything you need.
              </Heading>
                </CaptionBox>
              </Box>

            </Box>
            <Slider {...settings} style={isLarge ? { minWidth: '960px' } : {}}>
          {/* <Stack fill> */}
          {/* <Heading>Your Home in Tel Aviv</Heading> */}
          {images && images.map((edge, i) =>
            (i > 17 && i < 20) ? 

(            <Box key={i}>
            <ImageWrapper basis='2/3' flex='grow'>
              <Img key={`${images[i].node.fluid.src}_stat`}
                fluid={images[i].node.fluid} style={{
                  // height: '600px' 
                }} />
            </ImageWrapper>

            </Box>) : null

          )}
          {/* </Stack> */}

        </Slider>
          </Box>

        </Box>
      </main>
    </Layout>
  );
};



const mapStateToProps = ({ themeType, browser }) => {
  return { themeType, browser };
};

const mapDispatchToProps = (dispatch) => {
  return { themeSwitch: () => dispatch({ type: `CHANGE_THEME` }) };
};

const ConnectedBlogIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogIndex);



export default ConnectedBlogIndex;



export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allImageSharp(filter: {original: {src: {regex: "/SPACE-/"}}}) {
      edges {
        node {
          id
          fluid(maxHeight: 1200) {
            ...GatsbyImageSharpFluid
            }
        }
      }
    }
  }
`;

          // fluid(maxHeight: 500) {
          //   ...GatsbyImageSharpFluid
          // }